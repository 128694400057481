import React from "react"
import Header from "../header"
import Footer from "../../components/common/freightforwardingfooter"
import FreightForwarding from "../../components/common/freight-forwarding"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import { useTranslation, I18nextContext ,Link } from "gatsby-plugin-react-i18next"

const GATSBY_LANDING_PAGE_URL = process.env.GATSBY_LANDING_PAGE_URL
const FreightForwardingSofware = () => {
  const { language } = React.useContext(I18nextContext)
  const { t } = useTranslation()
  const buildCanonicalUrl = () =>
    language === "en"
      ? GATSBY_LANDING_PAGE_URL + "/product/freight-forwarding-sofware/"
      : GATSBY_LANDING_PAGE_URL + `/${language}/product/freight-forwarding-sofware/`

  const canoncalUrl = buildCanonicalUrl();
  
  const buildHrefUrl = () =>
  language === "en"
    ? JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL + "/"))
    : JSON.parse(JSON.stringify(GATSBY_LANDING_PAGE_URL +`/${language}/`))
const hrefUrl = buildHrefUrl();
  return (
    <div className="Layout">
      <Header />
      <FreightForwarding />
      <Footer />
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {t(
            "No.1 Freight Forwarding Software - Freight Management System | Logipulse"
          )}
        </title>

        <Link rel="canonical" href={canoncalUrl} />
        <Link rel="alternate" href={hrefUrl} hreflang={language} />
        <meta
          name="description"
          content={t("Get world-class freight forwarding software at the best price. Logipulse’s easy-to-use freight management software helps freight forwarders to control and manage their operations seamlessly from anywhere, anytime. Book your freight software from Logistics.")}
        />       
        <meta
          name="Keywords"
          content={t("freight forwarding software, freight management software, freight software")}
          data-react-helmet="true"
        />
        <meta
          property="og:type"
          content="article">
          </meta>
        <meta property="og:title"
        content=  {t(
          "No.1 Freight Forwarding Software - Freight Management System | Logipulse"
        )}>

        </meta>
        <meta property="og:url" content="https://www.logipulse.com/product/freight-forwarding-sofware/"></meta>
       
      </Helmet>
    </div>
  )
}
export default FreightForwardingSofware

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
