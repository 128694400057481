import React, { Component } from 'react';
import { Link, Trans, I18nextContext } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import "../../styles/threeplwarehousing.scss"
import Photo from "../Photo";
import "../../styles/freight-forwarding.scss";

class FreightForwarding extends Component {
    render() {
        return (
            <div>
                <div className="banner-feature">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="content-imgs">
                                    <div className="pricings_in_details customs_agency_main" lang={I18nextContext._currentValue.language}>
                                        <Link to="/"><Trans>HOME</Trans></Link> / <div className="featuress"><Link to="/product/"><Trans>PRODUCTS</Trans></Link> / </div>
                                        <div className="warehouse"><Trans>FREIGHT FORWARDING</Trans></div>
                                    </div>
                                    <div className="sub_main_customdetails" lang={I18nextContext._currentValue.language}><Trans>Freight Forwarding Software</Trans></div>
                                    <div className="customs_third_content" lang={I18nextContext._currentValue.language}>
                                        <Trans>A good freight forwarder will take on the responsibility of ensuring that the consignment entrusted to him reaches its destination safe and without damage.</Trans>
                                        <br />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row freight-forwarding-software" lang={I18nextContext._currentValue.language}>
                        <div className="col-md-6 image_airght">
                            <Photo
                                src="freightforwardingsoftware.png"
                                className="freight_brands_images"
                                alt=""
                            />
                        </div>
                        <div className="col-md-6 pl-md-0">
                            <div className="freight_forwarding_heads" lang={I18nextContext._currentValue.language}>
                                <Trans>
                                    Freight management and logistics management is important now than ever, especially in today’s world where the borders between countries get more blurred. Visibility, control, efficiency and speed over operations are critical in saving time and revenue.
                                </Trans>
                                <br />
                                <br />
                                <Trans>
                                    A freight forwarding software automates the planning and coordinating of movement of goods, thereby making life for the freight forwarder much easier. This eliminates the need for paperwork; also cutting the operational cost considerably.
                                </Trans>
                                <br />
                                <br />
                               
                                <Trans>Logipulse is a freight management software providing you with a SaaS-based solution that helps you manage your freight forwarding business, end-to-end, and improve your operations.</Trans>
                                <Trans>Our solution helps you achieve success in the least amount of time, with the fewest resources, to help implement this solution.</Trans>
                                
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <h2 className="freight_forwarding_heading" lang={I18nextContext._currentValue.language}><Trans>Why Choose Freight Forwarding Software for your Business?</Trans></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="freight_forwarding_subheading" lang={I18nextContext._currentValue.language}><Trans>With almost everything now being digitally available it was inevitable that the freight forwarding industry would also follow suit. Many freight forwarders have turned to a freight forwarding software to help manage their operations, inventory and shipments rather than relying on a third party.</Trans></div>
                            <div className="freight_forwarding_keys" lang={I18nextContext._currentValue.language}><Trans>Some key points where a freight management software makes a difference:</Trans></div>
                        </div>
                    </div>

                    <div className="row freight-forwarding-one" lang={I18nextContext._currentValue.language}>
                        <div className="col-sm-6">
                            <div className="freight-forwardings" lang={I18nextContext._currentValue.language}>
                                <h1 className="freight-forwarding-page" lang={I18nextContext._currentValue.language}><Trans>Automated Operations</Trans></h1>
                                <div className="freight-forwarding-body" lang={I18nextContext._currentValue.language}>
                                    <p><Trans>Logipulse is a freight forwarding solution that automates your everyday tasks increasing efficiency in the workflow as well as reducing the risk of human error. It is also cheaper than hiring  third-party service providers.</Trans> 
                                    <Trans>Document handling is also more effective with easy attachments at every state of the shipment.</Trans></p>

                                </div>
                            </div>
                            <div className="freight-forwardings" lang={I18nextContext._currentValue.language}>
                                <h1 className="freight-forwarding-page" lang={I18nextContext._currentValue.language}><Trans>Analytics</Trans></h1>
                                <div className="freight-forwarding-body" lang={I18nextContext._currentValue.language}>
                                    <p><Trans>A good freight management system, like Logipulse, will have real-time analytics which helps you understand what areas of your operations need more improvement, in turn this can also aid in effective decision making.</Trans></p>

                                </div>
                            </div>
                            

                        </div>
                        <div className="col-sm-6" >
                           

                        <div className="freight-forwarding-two" lang={I18nextContext._currentValue.language}>
                                <h1 className="freight-forwarding-page" lang={I18nextContext._currentValue.language}><Trans>Be in control</Trans></h1>
                                <div className="freight-forwarding-body" lang={I18nextContext._currentValue.language}>
                                    <p><Trans>Using logistics management software not only automates your operations but also keeps you in the know at every step of a shipment life cycle.</Trans> 
                                    <Trans>Logipulse supports alerts, notifications, triggers and internal messaging all which help in obtaining complete  real-time visibility into your operations.</Trans></p>
                                </div>
                            </div>

                            <div className="freight-forwarding-two" lang={I18nextContext._currentValue.language}>
                                <h1 className="freight-forwarding-page" lang={I18nextContext._currentValue.language}><Trans>Finance Module</Trans></h1>
                                <div className="freight-forwarding-body" lang={I18nextContext._currentValue.language}>
                                    <p><Trans>Logipulse boasts a detailed finance application fully integrated with the operations application with extensive and in-depth reports including profit and loss which allows you to monitor the financial well-being of the company. Invoicing is also made easier.</Trans></p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="freight_forwarding_heading" lang={I18nextContext._currentValue.language}><Trans>Different types of Freight Forwarding Software</Trans></h1>
                        </div>
                    </div>

                    <div className="freight_forwarding_main_section" lang={I18nextContext._currentValue.language}>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="freight_forwarding_shodow_box_header"lang={I18nextContext._currentValue.language} >
                                    <div className="ico">
                                        <Photo src="flight_black_24dp.svg" alt="Workflow and Approvals" />
                                    </div>
                                    <div className="freight_forwarding_box_text" lang={I18nextContext._currentValue.language}><Link to="/product/air-freight-software"><Trans>Air Freight Management Software</Trans></Link></div>
                                </div>
                                <div className="freight_forwarding_content" lang={I18nextContext._currentValue.language}><Trans>Easy management of all your air shipments with tracking capability at the item level. With Logipulse, important documents like Master and House AWBs, manifests and customs documents etc can be uploaded directly to the shipment or job, thereby increasing the efficiency of operations.</Trans></div>
                            </div>
                            <div className="col-sm-4">
                                <div className="freight_forwarding_shodow_box_header" lang={I18nextContext._currentValue.language}>
                                    <div className="ico" lang={I18nextContext._currentValue.language}>
                                        <Photo src="directions_boat_black_24dp.svg" alt="Workflow and Approvals" />
                                    </div>
                                    <div className="freight_forwarding_box_text" lang={I18nextContext._currentValue.language}><Link to="/product/ocean-freight-software"><Trans>Ocean Freight Management Software</Trans></Link></div>
                                </div>
                                <div className="freight_forwarding_content"><Trans>Logipulse makes for easy creation and management of consolidated and House shipments. Easy container tracking as well as all necessary documents can be uploaded as well as email from the system itself using inbuilt email engine. This feature is available across all 3 modes of freight.</Trans></div>
                            </div>
                            <div className="col-sm-4">
                                <div className="freight_forwarding_shodow_box_header" lang={I18nextContext._currentValue.language}>
                                    <div className="ico" lang={I18nextContext._currentValue.language}>
                                        <Photo src="local_shipping_black_24dp.svg" alt="Workflow and Approvals" />
                                    </div>
                                    <div className="freight_forwarding_box_text" lang={I18nextContext._currentValue.language}><Link to="/product/land-freight-software"><Trans>Land Freight Management Software</Trans></Link></div>
                                </div>
                                <div className="freight_forwarding_content"><Trans>Logipulse allows for FTL and LTL shipments as well as consolidations and house shipments. The system will also allow to create quotations, convert to shipments and track shipments. In addition, all finance matters can also be handled through the software.</Trans></div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="row">
                        <div className="col-sm-12">
                            <h3 className="freight_forwarding_heading" lang={I18nextContext._currentValue.language}><Trans>What Makes Logipulse Different from Other Freight Forwarding Software?</Trans></h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="freight-forwarding-body" lang={I18nextContext._currentValue.language}>
                               <p><Trans>Unlike most freight forwarding software available in the market, Logipulse has a zero-processing fee at the time of onboarding. Another feature that sets us apart is our extensive and detailed event engine as well as our alerts and notifications which are available over in-system, email, SMS and WhatsApp too. Logipulse also facilitates very specific user management. You can allocate and decide who in your organisation has permission to view sensitive data or confidential information, or even how much access to that particular permission is allocated. Perhaps one of the best is our pay as you go feature, and you can cancel at any time.</Trans></p>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <h1 className="freight_forwarding_heading" lang={I18nextContext._currentValue.language}><Trans>Key Features of Logipulse Freight Forwarding Software</Trans></h1>
                        </div>
                    </div>

                    <div className="freight_forwarding_list">
                        <div className="row">
                            <div className="col-sm-4">
                                <ul className="freight_forwarding_listing" lang={I18nextContext._currentValue.language}>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                        <span className="freight_forwarding_key" lang={I18nextContext._currentValue.language}><Trans>Multi Language</Trans></span></li>
                                    <li lang={I18nextContext._currentValue.language}> <Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                        <span className="freight_forwarding_key" lang={I18nextContext._currentValue.language}><Trans>Multi Branch and Agency</Trans></span></li>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                         <span className="freight_forwarding_key" lang={I18nextContext._currentValue.language}><Trans>Pleasant User Experience</Trans></span></li>
                                        <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow freight_forwarding_arrows"
                                        alt=""
                                    />{" "}
                                        <span className="freight_forwarding_subkeys" lang={I18nextContext._currentValue.language}><Trans>Multi-Currency Tax Enabled Finance Module</Trans></span></li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <ul className='freight_forwarding_listing'>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                        <span className="freight_forwarding_key" lang={I18nextContext._currentValue.language}><Trans>Customisable Report Engine</Trans></span></li>
                                    <li lang={I18nextContext._currentValue.language}>
                                        <Photo
                                            src="KnowMore-Right Arrow-H12.svg"
                                            className="testing-arrow"
                                            alt=""
                                        />{" "}
                                        <span className="freight_forwarding_key" lang={I18nextContext._currentValue.language}><Trans>Realtime Status Tracking</Trans></span>
                                    </li>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                         <span className="freight_forwarding_key" lang={I18nextContext._currentValue.language}><Trans>Alerts and Notifications</Trans></span></li>
                                        <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow freight_forwarding_arrows"
                                        alt=""
                                    />{" "}
                                         <span className="freight_forwarding_subkeys" lang={I18nextContext._currentValue.language}><Trans>User Management–Permissions and Authorisations</Trans></span></li>
                                </ul>
                            </div>
                            <div className="col-sm-4">
                                <ul className='freight_forwarding_listing' lang={I18nextContext._currentValue.language}>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                        <Trans>Data Analytical KPI Dashboard</Trans></li>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                        <Trans>AI based SaaS platform</Trans></li>
                                    <li lang={I18nextContext._currentValue.language}>
                                        <Photo
                                            src="KnowMore-Right Arrow-H12.svg"
                                            className="testing-arrow"
                                            alt=""
                                        />{" "}
                                        <Trans>Mobile, Tablet, Desktop & Web</Trans>
                                    </li>
                                    <li lang={I18nextContext._currentValue.language}><Photo
                                        src="KnowMore-Right Arrow-H12.svg"
                                        className="testing-arrow"
                                        alt=""
                                    />{" "}
                                       <Trans>In-built Sales CRM Module</Trans></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="end_divis_product">
                    <div className="header_contents_sections text-right"></div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <Photo
                                    src="Bottom Image-11.svg"
                                    className="innerimage"
                                    alt=""
                                />
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default FreightForwarding;

export const query = graphql`
                query($language: String!) {
                    locales: allLocale(filter: {language: {eq: $language}}) {
                    edges {
                    node {
                    ns
                  data
                language
        }
      }
    }
  }
                `;
